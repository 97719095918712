<template>
  <div class="divBox">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <!-- <el-form-item label="服务区域" :rules="[{ required: true, message: '请选择服务区域', trigger: 'blur' }]" prop="serveStreetNames">
        <v-select multiple placeholder="请选择" :options="serveStreetNamesList" v-model="form.serveStreetNames"></v-select>
      </el-form-item> -->
      <el-form-item
        label="服务分类"
        :rules="[
          { required: true, message: '请选择服务分类', trigger: 'blur' },
        ]"
        prop="serviceType"
      >
        <v-select
          placeholder="请选择"
          :options="severTypeList"
          v-model="form.serviceType"
          @change="handelChangeServeType"
        ></v-select>
      </el-form-item>
      <el-form-item
        label="子分类"
        :rules="[
          { required: true, message: '请选择服务分类', trigger: 'blur' },
        ]"
        prop="serviceType"
      >
        <v-select
          placeholder="请选择"
          :options="detailSeverTypeList"
          v-model="form.serviceTypeLv2"
        ></v-select>
      </el-form-item>
      <el-form-item
        label="服务标题"
        :rules="[
          { required: true, message: '请输入服务标题', trigger: 'blur' },
        ]"
        prop="title"
      >
        <v-input placeholder="请输入" v-model="form.title"></v-input>
      </el-form-item>
      <el-form-item
        label="副标题"
        :rules="[{ required: true, message: '请输入副标题', trigger: 'blur' }]"
        prop="subTitle"
      >
        <v-input placeholder="请输入" v-model="form.subTitle"></v-input>
      </el-form-item>
      <el-form-item
        label="发布人"
        :rules="[{ required: true, message: '请输入发布人', trigger: 'blur' }]"
        prop="broadcaster"
      >
        <v-input placeholder="请输入" v-model="form.broadcaster"></v-input>
      </el-form-item>
      <el-form-item
        label="服务封面"
        :rules="[
          {
            required: true,
            message: '请上传服务封面',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="coverPicture"
      >
        <v-upload
          tips="建议上传900px*383px图片"
          :limit="1"
          :imgUrls.sync="form.coverPicture"
        />
      </el-form-item>
      <el-form-item
        label="内容图片"
        :rules="[
          {
            required: false,
            message: '请上传服务封面',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="contentPictures"
      >
        <v-upload
          tips="建议上传900px*383px图片"
          :limit="9"
          :imgUrls.sync="form.contentPictures"
        />
      </el-form-item>
      <el-form-item
        label="服务机构名称"
        :rules="[
          { required: true, message: '请输入服务机构名称', trigger: 'blur' },
        ]"
        prop="orgName"
      >
        <v-input placeholder="请输入" v-model="form.orgName"></v-input>
      </el-form-item>
      <el-form-item
        label="联系方式"
        :rules="[
          { required: true, message: '请输入联系方式', trigger: 'blur' },
        ]"
        prop="contactMobile"
      >
        <div class="form-item">
          <v-input
            placeholder="请输入"
            v-model="form.contactMobile"
            :disabled="isEdit"
            @blur="handelInputBlur"
          ></v-input>
          <v-button
            style="margin-left: 10px"
            text="编辑"
            v-if="form.id"
            @click="handelClick"
          ></v-button>
        </div>
      </el-form-item>
      <el-form-item
        label="服务方式"
        :rules="[
          { required: true, message: '请输入服务方式', trigger: 'blur' },
        ]"
        prop="serviceMode"
      >
        <v-input placeholder="请输入" v-model="form.serviceMode"></v-input>
      </el-form-item>
      <!-- <el-form-item
        label="经营时间"
        :rules="[{ required: true, message: '经营时间', trigger: 'blur' }]"
      >
        <v-time
          v-model="createTime"
          valueFormat="HH:mm"
          format="HH:mm"
          :pickerOptions="null"
          is-range
        ></v-time>
      </el-form-item> -->
      <el-form-item
        label="详细地址"
        :rules="[
          { required: true, message: '请标注详细地址', trigger: 'blur' },
        ]"
        prop="address"
      >
        <v-input
          placeholder="请标注详细地址"
          isReadonly
          v-model="form.address"
        ></v-input>
        <v-button
          style="margin-left: 10px"
          text="地图标注"
          @click="showMapDialog = true"
        ></v-button>
      </el-form-item>
      <el-form-item
        label="门牌号"
        :rules="[{ required: true, message: '请输入门牌号', trigger: 'blur' }]"
        prop="doorplate"
      >
        <v-input placeholder="请输入门牌号" v-model="form.doorplate"></v-input>
      </el-form-item>
      <el-form-item
        label="服务介绍"
        :rules="[
          { required: true, message: '请输入服务介绍', trigger: 'blur' },
        ]"
        prop="description"
      >
        <div id="editor">
          <v-ueditor :maxLength="20000" v-model="form.description" />
        </div>
      </el-form-item>
    </form-panel>
    <v-map
      :selectedLng="Number(form.lng)"
      :selectedLat="Number(form.lat)"
      :showMapDialog.sync="showMapDialog"
      @setLngAndLat="setLngAndLat"
    ></v-map>
  </div>
</template>

<script>
import {
  saveServiceInfo,
  getServiceInfoById,
  getInitInfo,
  getServiceTypeLv2,
  serviceEdit,
} from "./api.js";
import { setAESCbcEncrypt } from "@/utils/common";
export default {
  name: "partnerForm",
  data() {
    return {
      showMapDialog: false, //地图控件
      isDialog: false,
      submitConfig: {
        queryUrl: getServiceInfoById,
        submitUrl: saveServiceInfo,
      },
      form: {
        id: null,
        // serveStreetNames: [], //服务区域
        serviceType: "", //服务分类
        serviceTypeLv2: "", //服务分类详细
        title: "", //标题
        subTitle: "", //副标题
        broadcaster: "", //发布人
        coverPicture: "", //服务封面
        orgName: "", //机构名称
        contactMobile: "", //联系方式
        serviceMode: "", //服务方式
        // openTime: "00:00", //经营时间
        // closeTime: "23:59", //经营时间
        address: "", //商家地址
        doorplate: "", //门牌号
        description: "", //服务地址
        lng: "",
        lat: "",
        contentPictures:"",//内容图片
      },
      serveStreetNamesList: [],
      communityId: null,
      onOff: true, // 是否进入编辑
      severTypeList: [], //服务分类数据
      detailSeverTypeList: [], //服务分类详情数据
      firstIn: false, //是否为第一次加载页面
      isEdit: false,
    };
  },
  computed: {
    createTime: {
      get() {
        return !this.form.openTime && !this.form.closeTime
          ? []
          : [this.form.openTime, this.form.closeTime];
      },
      set(val) {
        [this.form.openTime, this.form.closeTime] = val || ["00:00", "23:59"];
      },
    },
  },
  // watch: {
  //   'form.serviceType': {
  //     handler(newValue) {
  //       if (newValue) {
  //         // this.form.serviceTypeLv2 = '';
  //         this.getServiceTypeLv2(newValue);
  //       }
  //       console.log('newValue----->', newValue);
  //     },
  //     deep: true,
  //   },
  // },
  created() {
    this.getInitInfo();
    const { id } = this.$route.query;
    // this.form.communityId = communityId;
    if (id !== undefined) {
      this.isEdit = true;
      this.$nextTick(() => {
        this.$refs.formPanel.getData({ id });
      });
    }

    // this.submitConfig.submitUrl =
    //   id !== undefined ? updateTopicURL : addTopicURL;
    // this.onOff = id !== undefined;
    this.$setBreadList(id ? "编辑" : "新增");
  },
  mounted() {
    this.firstIn = true;
  },
  methods: {
    //编辑手机号
    handelInputBlur() {
      if (this.form.id) {
        const params = {
          id: this.form.id,
          contactMobile: setAESCbcEncrypt(this.form.contactMobile),
        };
        this.$axios.post(serviceEdit, params).then((res) => {
          if (res.code == 200) {
            this.isEdit = true;
            this.$message(res.msg);
          }
        });
      }
    },
    handelClick() {
      this.form.contactMobile=""
      this.isEdit = false;
    },
    // 选择服务类别
    handelChangeServeType(value) {
      if (!this.firstIn) {
        this.form.serviceTypeLv2 = "";
      } else {
        this.firstIn = false;
      }
      this.getServiceTypeLv2(value);
    },
    //获取小类数据
    getServiceTypeLv2(code) {
      this.$axios.get(`${getServiceTypeLv2}?code=${code}`).then((res) => {
        if (res.code == 200) {
          const { list } = res.data;
          list.forEach((item) => {
            item.value = item.dictKey;
            item.label = item.dictValue;
          });
          this.detailSeverTypeList = list;
          console.log("res----->", res);
        }
      });
    },
    //获取服务类别列表
    getInitInfo() {
      const code = "convenience_service_type";
      this.$axios.get(`${getInitInfo}?code=${code}`).then((res) => {
        if (res.code == 200) {
          const { convenienceServiceTypeList } = res.data;
          convenienceServiceTypeList.forEach((item) => {
            item.label = item.dictValue;
            item.value = item.dictKey;
          });
          this.severTypeList = convenienceServiceTypeList;
        }
      });
    },
    setLngAndLat(l) {
      if (!l.lng || !l.lat) {
        return false;
      }
      this.form.address = l.address;
      this.form.lng = l.lng;
      this.form.lat = l.lat;
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key] || this.form[key];
      });
      // if (this.form.serveStreetNames) {
      //   this.form.serveStreetNames = this.form.serveStreetNames.split(",");
      // }
    },
    submitBefore() {
      if (this.form.id) {
        delete this.form.contactMobile;
      } else {
        this.form.contactMobile = setAESCbcEncrypt(this.form.contactMobile);
      }
      // if (this.form.serveStreetNames.length > 0) {
      //   this.form.serveStreetNames = this.form.serveStreetNames.join(",");
      // } else {
      //   this.form.serveStreetNames = "";
      // }
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
  .form-item {
    display: flex;
  }
}

::v-deep .dialog-footer {
  display: flex;
  justify-content: space-between;
}
</style>
