var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"divBox"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"form":_vm.form,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"服务分类","rules":[
        { required: true, message: '请选择服务分类', trigger: 'blur' },
      ],"prop":"serviceType"}},[_c('v-select',{attrs:{"placeholder":"请选择","options":_vm.severTypeList},on:{"change":_vm.handelChangeServeType},model:{value:(_vm.form.serviceType),callback:function ($$v) {_vm.$set(_vm.form, "serviceType", $$v)},expression:"form.serviceType"}})],1),_c('el-form-item',{attrs:{"label":"子分类","rules":[
        { required: true, message: '请选择服务分类', trigger: 'blur' },
      ],"prop":"serviceType"}},[_c('v-select',{attrs:{"placeholder":"请选择","options":_vm.detailSeverTypeList},model:{value:(_vm.form.serviceTypeLv2),callback:function ($$v) {_vm.$set(_vm.form, "serviceTypeLv2", $$v)},expression:"form.serviceTypeLv2"}})],1),_c('el-form-item',{attrs:{"label":"服务标题","rules":[
        { required: true, message: '请输入服务标题', trigger: 'blur' },
      ],"prop":"title"}},[_c('v-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.title),callback:function ($$v) {_vm.$set(_vm.form, "title", $$v)},expression:"form.title"}})],1),_c('el-form-item',{attrs:{"label":"副标题","rules":[{ required: true, message: '请输入副标题', trigger: 'blur' }],"prop":"subTitle"}},[_c('v-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.subTitle),callback:function ($$v) {_vm.$set(_vm.form, "subTitle", $$v)},expression:"form.subTitle"}})],1),_c('el-form-item',{attrs:{"label":"发布人","rules":[{ required: true, message: '请输入发布人', trigger: 'blur' }],"prop":"broadcaster"}},[_c('v-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.broadcaster),callback:function ($$v) {_vm.$set(_vm.form, "broadcaster", $$v)},expression:"form.broadcaster"}})],1),_c('el-form-item',{attrs:{"label":"服务封面","rules":[
        {
          required: true,
          message: '请上传服务封面',
          trigger: ['change', 'blur'],
        },
      ],"prop":"coverPicture"}},[_c('v-upload',{attrs:{"tips":"建议上传900px*383px图片","limit":1,"imgUrls":_vm.form.coverPicture},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "coverPicture", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "coverPicture", $event)}}})],1),_c('el-form-item',{attrs:{"label":"内容图片","rules":[
        {
          required: false,
          message: '请上传服务封面',
          trigger: ['change', 'blur'],
        },
      ],"prop":"contentPictures"}},[_c('v-upload',{attrs:{"tips":"建议上传900px*383px图片","limit":9,"imgUrls":_vm.form.contentPictures},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "contentPictures", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "contentPictures", $event)}}})],1),_c('el-form-item',{attrs:{"label":"服务机构名称","rules":[
        { required: true, message: '请输入服务机构名称', trigger: 'blur' },
      ],"prop":"orgName"}},[_c('v-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.orgName),callback:function ($$v) {_vm.$set(_vm.form, "orgName", $$v)},expression:"form.orgName"}})],1),_c('el-form-item',{attrs:{"label":"联系方式","rules":[
        { required: true, message: '请输入联系方式', trigger: 'blur' },
      ],"prop":"contactMobile"}},[_c('div',{staticClass:"form-item"},[_c('v-input',{attrs:{"placeholder":"请输入","disabled":_vm.isEdit},on:{"blur":_vm.handelInputBlur},model:{value:(_vm.form.contactMobile),callback:function ($$v) {_vm.$set(_vm.form, "contactMobile", $$v)},expression:"form.contactMobile"}}),(_vm.form.id)?_c('v-button',{staticStyle:{"margin-left":"10px"},attrs:{"text":"编辑"},on:{"click":_vm.handelClick}}):_vm._e()],1)]),_c('el-form-item',{attrs:{"label":"服务方式","rules":[
        { required: true, message: '请输入服务方式', trigger: 'blur' },
      ],"prop":"serviceMode"}},[_c('v-input',{attrs:{"placeholder":"请输入"},model:{value:(_vm.form.serviceMode),callback:function ($$v) {_vm.$set(_vm.form, "serviceMode", $$v)},expression:"form.serviceMode"}})],1),_c('el-form-item',{attrs:{"label":"详细地址","rules":[
        { required: true, message: '请标注详细地址', trigger: 'blur' },
      ],"prop":"address"}},[_c('v-input',{attrs:{"placeholder":"请标注详细地址","isReadonly":""},model:{value:(_vm.form.address),callback:function ($$v) {_vm.$set(_vm.form, "address", $$v)},expression:"form.address"}}),_c('v-button',{staticStyle:{"margin-left":"10px"},attrs:{"text":"地图标注"},on:{"click":function($event){_vm.showMapDialog = true}}})],1),_c('el-form-item',{attrs:{"label":"门牌号","rules":[{ required: true, message: '请输入门牌号', trigger: 'blur' }],"prop":"doorplate"}},[_c('v-input',{attrs:{"placeholder":"请输入门牌号"},model:{value:(_vm.form.doorplate),callback:function ($$v) {_vm.$set(_vm.form, "doorplate", $$v)},expression:"form.doorplate"}})],1),_c('el-form-item',{attrs:{"label":"服务介绍","rules":[
        { required: true, message: '请输入服务介绍', trigger: 'blur' },
      ],"prop":"description"}},[_c('div',{attrs:{"id":"editor"}},[_c('v-ueditor',{attrs:{"maxLength":20000},model:{value:(_vm.form.description),callback:function ($$v) {_vm.$set(_vm.form, "description", $$v)},expression:"form.description"}})],1)])],1),_c('v-map',{attrs:{"selectedLng":Number(_vm.form.lng),"selectedLat":Number(_vm.form.lat),"showMapDialog":_vm.showMapDialog},on:{"update:showMapDialog":function($event){_vm.showMapDialog=$event},"update:show-map-dialog":function($event){_vm.showMapDialog=$event},"setLngAndLat":_vm.setLngAndLat}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }